import { Check } from 'lucide-react';
import { loadStripe } from '@stripe/stripe-js';


const PlanCard = ({ price, title, description, features, isPopular, priceId }) => {
    const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY}`);
    

    //     try {
    //         const stripe = await stripePromise;
    
    //         const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/create-checkout-session`, {
    //             method: 'POST',
    //             headers: {
    //                 'Content-Type': 'application/json',
    //             },
    //             body: JSON.stringify({ priceId }),  // Use priceId directly from props
    //         });
    
    //         if (!response.ok) {
    //             throw new Error(`HTTP error! status: ${response.status}`);
    //         }
    
    //         // Check if the response has content before parsing it
    //         const text = await response.text();  // Get response as text first
    //         if (!text) {
    //             throw new Error('Empty response from the server');
    //         }
    
    //         // Parse the response only if it's not empty
    //         const session = JSON.parse(text);
    
    //         await stripe.redirectToCheckout({ sessionId: session.id });
    
    //     } catch (error) {
    //         console.error('Error creating checkout session:', error);
    //     }
    // };
    
    const createCheckoutSession = async () => {
      console.log('createCheckoutSession function called');  // Log when function is called
      try {
          const stripe = await stripePromise;
          console.log('stripePromise resolved');  // Log when stripePromise resolves
  
          const response = await fetch(`${process.env.REACT_APP_API_URL}/v1/create-checkout-session`, {
              method: 'POST',
              headers: {
                  'Content-Type': 'application/json',
              },
              body: JSON.stringify({ priceId: priceId }),
          });
  
          console.log('fetch request sent');  // Log when the fetch request is sent
  
          if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          const session = await response.json();
          console.log('fetch response received', session);  // Log the response from the fetch call
  
          // Redirect to Stripe Checkout
          const result = await stripe.redirectToCheckout({ sessionId: session.id });
          console.log('redirectToCheckout called');  // Log when redirectToCheckout is called
  
          if (result.error) {
              console.error('Error during Stripe redirect:', result.error.message);
          }
      } catch (error) {
          console.error('Error creating checkout session:', error);
      }
  };
  
    
    return (
        <div className="plan-card border flex-col justify-between bg-white/20
         backdrop-blur-lg rounded-lg h-full p-6 hover:shadow-md text-left relative">
            {isPopular && <div className="popular-tag absolute
            -top-6 left-0 bg-indigo-500 text-white px-2 py-1 rounded-t-lg w-full text-center">Popular</div>}
            <div>
                <div className="inline-flex items-end">
                    <h1 className="font-extrabold text-3xl">{price}</h1>
                    <span className="ml-3 text-gray-500 align-bottom">/month</span>
                </div>
                <h2 className="font-bold text-xl my-2">{title}</h2>
                <p>{description}</p>
                <div className="border-t border-gray-400 opacity-25 my-3 flex-grow"></div>
                <ul>
                    {features.map((feature, index) => (
                        <li key={index} className="flex items-center mb-2">
                            <div className="w-4 h-4 bg-indigo-500 rounded-full flex items-center justify-center mr-2">
                                <Check className="inline-block text-white w-3 h-3" />
                            </div>
                            <p>{feature}</p>
                        </li>
                    ))}
                </ul>
            </div>
            <div>
                <button
                    onClick={createCheckoutSession}  // Directly call the function
                    className="bg-indigo-500 hover:bg-indigo-600 py-2 mt-3 rounded-lg text-white w-full"
                >
                    Select Plan
                </button>
            </div>
        </div>
    );
};

export default PlanCard;
